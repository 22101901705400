var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.logType
    ? _c(
        "div",
        {
          staticClass: "log-message",
          class: [
            "log-message-type--" + _vm.logType,
            { "log-message-error": _vm.statuslog.contact_log_status > 500 }
          ]
        },
        [
          _c("div", { staticClass: "log-message__header" }, [
            _c("span", { staticClass: "log-message__date" }, [
              _vm._v(_vm._s(_vm.date))
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "log-message__name" }, [
              _vm._v(_vm._s(_vm.name))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "log-message__body" }, [
            _c(
              "div",
              { staticClass: "log-message__text" },
              [_vm._t("text")],
              2
            ),
            _vm._v(" "),
            _c("img", {
              staticClass: "log-message__avatar",
              attrs: { src: _vm.image }
            })
          ]),
          _vm._v(" "),
          _vm.hasActionSlot
            ? _c("div", { staticClass: "chat-actions" }, [_vm._t("actions")], 2)
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0f7a8adb", { render: render, staticRenderFns: staticRenderFns })
  }
}