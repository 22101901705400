var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "case-menu",
      class: { "text-mode": _vm.textMode, "input-mode": _vm.inputMode }
    },
    [
      _c("lead-comment", {
        attrs: {
          lead: _vm.lead,
          textMode: _vm.textMode,
          "set-case-data": _vm.caseDataMode
        },
        on: {
          input: function(updatedLead) {
            return _vm.$emit("updateLead", updatedLead)
          },
          textmode: _vm.setTextMode,
          focus: function($event) {
            _vm.inputMode = true
          },
          blur: function($event) {
            _vm.inputMode = false
          },
          commenterror: function($event) {
            return _vm.$emit("commenterror")
          }
        }
      }),
      _vm._v(" "),
      _vm.lead.contact_state != 4 &&
      typeof _vm.lead.customer_name !== "undefined"
        ? _c(
            "a",
            {
              staticClass: "case-menu__item case-menu__item--tasks",
              class: {
                "case-menu__item--verified": _vm.lead.customer_verified
              },
              attrs: { href: "javascript:void(0)" },
              on: { click: _vm.fillCustomerData }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "case-menu__item__icon",
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 24 24"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M22.28,14.47l-2.25-2.25c-.07-.07-.15-.12-.24-.16-.09-.04-.19-.06-.29-.06s-.2.02-.29.06c-.09.04-.17.09-.24.16l-6.97,6.97v3.31h3.31l6.97-6.97c.07-.07.12-.15.16-.24.04-.09.06-.19.06-.29s-.02-.2-.06-.29c-.04-.09-.09-.17-.16-.24ZM14.69,21h-1.19v-1.19l3.75-3.75,1.19,1.19-3.75,3.75ZM19.5,16.19l-1.19-1.19,1.19-1.19,1.19,1.19-1.19,1.19Z"
                    }
                  }),
                  _c("path", {
                    attrs: {
                      d:
                        "M12.75,11.25h-4.5c-.6,0-1.17.24-1.59.66-.42.42-.66.99-.66,1.59v.75h1.5v-.75c0-.2.08-.39.22-.53.14-.14.33-.22.53-.22h4.5c.2,0,.39.08.53.22s.22.33.22.53v.75h1.5v-.75c0-.6-.24-1.17-.66-1.59-.42-.42-.99-.66-1.59-.66Z"
                    }
                  }),
                  _c("path", {
                    attrs: {
                      d:
                        "M10.5,10.5c.59,0,1.17-.18,1.67-.51.49-.33.88-.8,1.1-1.35.23-.55.29-1.15.17-1.73-.12-.58-.4-1.12-.82-1.54-.42-.42-.95-.71-1.54-.82-.58-.12-1.19-.06-1.73.17-.55.23-1.02.61-1.35,1.1-.33.49-.51,1.07-.51,1.67,0,.8.32,1.56.88,2.12.56.56,1.33.88,2.12.88ZM10.5,6c.3,0,.59.09.83.25.25.16.44.4.55.67.11.27.14.58.09.87-.06.29-.2.56-.41.77-.21.21-.48.35-.77.41s-.59.03-.87-.09c-.27-.11-.51-.31-.67-.55-.16-.25-.25-.54-.25-.83,0-.4.16-.78.44-1.06.28-.28.66-.44,1.06-.44Z"
                    }
                  }),
                  _c("path", {
                    attrs: {
                      d:
                        "M9,22.5h-4.5c-.4,0-.78-.16-1.06-.44-.28-.28-.44-.66-.44-1.06V3c0-.4.16-.78.44-1.06.28-.28.66-.44,1.06-.44h12c.4,0,.78.16,1.06.44.28.28.44.66.44,1.06v7.5h-1.5V3H4.5v18h4.5v1.5Z"
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "case-menu__item__text" }, [
                _vm._v("Kundedata")
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.lead.contact_state != 4 && _vm.containsCaseData
        ? _c(
            "router-link",
            {
              staticClass: "case-menu__item",
              class: { "case-menu__item--verified": _vm.verifiedCaseData },
              attrs: { to: { name: "lead-view", params: { view: "products" } } }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "case-menu__item__icon",
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 24 24"
                  }
                },
                [
                  _c("path", { attrs: { d: "M15,4.5H6v1.5h9v-1.5Z" } }),
                  _c("path", { attrs: { d: "M15,7.5H6v1.5h9v-1.5Z" } }),
                  _c("path", { attrs: { d: "M10.5,10.5h-4.5v1.5h4.5v-1.5Z" } }),
                  _c("path", { attrs: { d: "M9,18h-3v1.5h3v-1.5Z" } }),
                  _c("path", {
                    attrs: {
                      d:
                        "M22.44,14.71c-.04-.09-.09-.17-.16-.24l-2.25-2.25c-.07-.07-.15-.12-.24-.16s-.19-.06-.29-.06-.2.02-.29.06-.17.09-.24.16l-6.97,6.97v3.31h3.31l6.97-6.97c.07-.07.12-.15.16-.24.04-.09.06-.19.06-.29s-.02-.2-.06-.29ZM14.69,21h-1.19v-1.19l3.75-3.75,1.19,1.19-3.75,3.75ZM19.5,16.19l-1.19-1.19,1.19-1.19,1.19,1.19-1.19,1.19Z"
                    }
                  }),
                  _c("path", {
                    attrs: {
                      d:
                        "M9,22.5h-4.5c-.4,0-.78-.16-1.06-.44-.28-.28-.44-.66-.44-1.06V3c0-.4.16-.78.44-1.06.28-.28.66-.44,1.06-.44h12c.4,0,.78.16,1.06.44.28.28.44.66.44,1.06v7.5h-1.5V3H4.5v18h4.5v1.5Z"
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "case-menu__item__text" }, [
                _vm._v("Sagsdata")
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass: "case-menu__item",
          attrs: { to: { name: "lead-view", params: { view: "kommentar" } } }
        },
        [
          _c(
            "svg",
            {
              staticClass: "case-menu__item__icon",
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 24 24"
              }
            },
            [
              _c("path", {
                attrs: {
                  d:
                    "M13.31,22.5l-1.31-.75,3-5.25h4.5c.4,0,.78-.16,1.06-.44s.44-.66.44-1.06V6c0-.4-.16-.78-.44-1.06s-.66-.44-1.06-.44H4.5c-.4,0-.78.16-1.06.44-.28.28-.44.66-.44,1.06v9c0,.4.16.78.44,1.06.28.28.66.44,1.06.44h6.75v1.5h-6.75c-.8,0-1.56-.32-2.12-.88-.56-.56-.88-1.33-.88-2.12V6c0-.8.32-1.56.88-2.12.56-.56,1.33-.88,2.12-.88h15c.8,0,1.56.32,2.12.88.56.56.88,1.33.88,2.12v9c0,.8-.32,1.56-.88,2.12-.56.56-1.33.88-2.12.88h-3.63l-2.56,4.5Z"
                }
              }),
              _c("path", {
                attrs: {
                  d: "M12.75,6.75h-1.5v3h-3v1.5h3v3h1.5v-3h3v-1.5h-3v-3Z"
                }
              })
            ]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "case-menu__item__text" }, [
            _vm._v("Send kommentar")
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-333c1dcd", { render: render, staticRenderFns: staticRenderFns })
  }
}