import Vue from 'vue';
import VueRouter from 'vue-router';
import VueAnalytics from 'vue-analytics';
import Vuex from 'vuex';

/** DEPENDENCIES */
import Moment from 'moment';
import 'es6-promise/auto';

/** SERVICES ** */
import DataStore from './services/datastore.js';

/** COMPONENTS */
import MainHeader from './components/main-header/main-header.vue';
import CreateProfile from './components/create-profile/create-profile.vue';
import LeadView from './components/lead/lead-view.vue';
import NemHeader from './components/nem-header/nem-hedaer.vue';
import DDAHeader from './components/dda-header/dda-header.vue';
import DDAFooter from './components/dda-footer/dda-footer.vue';
import ChooseLoginType from './components/profile-login/choose-login-type.vue';
import CustomerResetLogin from './components/profile-login/customer-reset-login.vue';
import WelcomeBack from './components/profile-login/welcome-back.vue';
import ProfileLogin from './components/profile-login/profile-login.vue';
import Frontpage from './components/frontpage.vue';
import Logout from './components/logout.vue';
import UserProfile from './components/user-profile/user-profile.vue';
import WelcomePage from './components/user-profile/welcome.vue';
import DroppointPage from './components/droppoint-page.vue';
import PartLogin from './components/profile-login/part-login.vue';
import Validate from './components/validate.vue';
import KYCView from './components/kyc-flow/kyc-view.vue';

/** EXTERNALS */
import '@babel/polyfill';
import './directives';
import './sass/output/frontend.scss';

/** INITIALIZING **/
Moment.locale('da');
Vue.use(VueRouter);
Vue.use(Vuex);

var routes = [ { path: '/', component: Frontpage, meta: { hideMainHeader: true } } ];

const Redirects = {
	konkurskarantaene: 'selskabsret',
	'tinglysning-af-skoede': 'tinglysning-af-skode',
	'juridisk-radgivning': 'lobende-radgivning',
	'mangler-ved-fast-ejendom': 'fast-ejendom',
	dodsbo: 'arveret',
	'ansaettelses-arbejdsret-arbejdsgiver': 'ansaettelsesret',
	'kob-af-virksomhed': 'virksomhedsoverdragelse',
	ophavsret: 'immaterialret',
	'salg-af-virksomhed': 'virksomhedsoverdragelse',
	'spaltning-og-fusion': 'fusion',
	'uskiftet-bo': 'arveret',
	'ophavsret-privat': 'immaterialret',
	'varemaerke-i-norge': 'varemaerkeregistrering',
	'varemaerke-i-eu': 'varemaerkeregistrering',
	'varemaerke-i-usa': 'varemaerkeregistrering',
	'kraenkelse-af-varemaerke': 'immaterialret',
	'overvagning-af-varemaerke': 'immaterialret',
	koeberradgivning: 'koberradgivning',
	transportskoede: 'tinglysning-af-skode'
};

var genereateUrl = function(path, newProduct, oldProduct) {
	return { path: path + oldProduct, redirect: path + newProduct };
};

for (let i in Redirects) {
	routes.push(genereateUrl('/start-din-bolig-sag/', Redirects[i], i));
}

for (let i in Redirects) {
	routes.push(genereateUrl('/start-sag/', Redirects[i], i));
}

for (let i in Redirects) {
	routes.push(genereateUrl('/ring-mig-op/', Redirects[i], i));
}

/** ROUTES **/
routes = routes.concat([
	/**  ORDER ROUTES **/
	{
		path: '/start-din-bolig-sag/:product',
		component: CreateProfile,
		meta: { hideMainHeader: true, systemNotification: false }
	},
	{
		path: '/start-sag/:product',
		component: CreateProfile,
		meta: { hideMainHeader: true, systemNotification: false }
	},
	{ path: '/ring-mig-op/:product', redirect: '/start-sag/:product' },
	{
		path: '/start-sag/:product/kvittering',
		component: CreateProfile,
		meta: { hideMainHeader: true, systemNotification: false }
	},
	{
		path: '/ring-mig-op/:product/kvittering',
		redirect: '/start-sag/:product/kvittering'
	},

	/** Campaign Routes */
	{
		path: '/:source/start-sag/:campaign',
		component: CreateProfile,
		meta: { hideMainHeader: true, callMe: false, systemNotification: false }
	},
	{
		path: '/:source/start-sag/:campaign/kvittering',
		component: CreateProfile,
		meta: { hideMainHeader: true, callMe: false, systemNotification: false }
	},

	{ path: '/:source/ring-mig-op/:campaign', redirect: '/:source/start-sag/:campaign' },
	{
		path: '/:source/ring-mig-op/:campaign/kvittering',
		redirect: '/:source/start-sag/:campaign/kvittering'
	},

	/** ORDER OPTION ROUTES */
	{ path: '/lead/:hash/:view?/:slide?', component: LeadView, name: 'lead-view', meta: { hideMainHeader: true } },

	/** OPEN DROPOINT **/
	{ path: '/droppoint/:hash', name: 'droppoint', component: DroppointPage, meta: { hideMainHeader: true } },

	//** ADMIN LOGIN */
	{
		path: '/validate',
		name: 'validate',
		component: Validate,
		meta: { hideMainHeader: false, centerHeader: true }
	},

	//** LOGIN  **/
	{
		path: '/login',
		name: 'choose-login',
		component: ChooseLoginType,
		meta: { showDDA: true, hideHeaders: true }
	},
	{
		path: '/logout',
		name: 'logout',
		component: Logout,
		meta: { showDDA: true, hideHeaders: true }
	},

	//** CUSTOMER AREA **/
	{
		path: '/velkommentilbage',
		name: 'customer-welcome-back',
		component: WelcomeBack,
		meta: { showDDA: true, hideHeaders: true }
	},
	{
		path: '/profil/reset',
		name: 'customer-reset-login',
		component: CustomerResetLogin,
		meta: { showDDA: true, hideHeaders: true }
	},
	{
		path: '/profil/:hash/login',
		name: 'login',
		component: ProfileLogin,
		meta: { hideMainHeader: false, centerHeader: true }
	},
	{ path: '/profil/:hash', name: 'profile', component: UserProfile, meta: { hideMainHeader: false, caseView: true } },
	{
		path: '/profil/:hash/newcase',
		name: 'profile-new-case',
		component: UserProfile,
		meta: { hideMainHeader: false, caseView: true }
	},
	//{ path: '/profil/:hash/rights', name: 'rights', component: UserRights, meta: {hideMainHeader: false}},
	{
		path: '/profil/:hash/velkommen',
		name: 'welcome',
		component: WelcomePage,
		meta: { hideMainHeader: false, centerHeader: true }
	},
	{
		path: '/profil/:hash/order/:orderhash/:modal?',
		name: 'case',
		component: UserProfile,
		meta: { hideMainHeader: false, caseView: true }
	},

	//** PART AREA **/
	{
		path: '/part/login',
		name: 'part-login',
		component: PartLogin,
		meta: { showDDA: true, hideHeaders: true }
	},
	{
		path: '/part/login/:hash',
		name: 'part-validate',
		component: PartLogin,
		meta: { hideMainHeader: false, centerHeader: true }
	},
	{
		path: '/part/login/:hash/password',
		name: 'part-password',
		component: PartLogin,
		meta: { hideMainHeader: false, centerHeader: true }
	},
	{
		path: '/part/:hash',
		name: 'part-profile',
		component: UserProfile,
		meta: { hideMainHeader: false, centerHeader: true, caseView: true }
	},
	{
		path: '/part/:hash/newcase',
		name: 'part-new-case',
		component: UserProfile,
		meta: { hideMainHeader: false, caseView: true }
	},
	{
		path: '/part/:hash/velkommen',
		name: 'part-welcome',
		component: WelcomePage,
		meta: { hideMainHeader: false, centerHeader: true }
	},
	{
		path: '/part/:hash/order/:orderhash/:modal?',
		name: 'part-case',
		component: UserProfile,
		meta: { hideMainHeader: false, caseView: true }
	},

	//KYC Validation
	{
		path: '/kyc/:uid',
		name: 'kyc-view',
		component: KYCView,
		meta: { showDDA: true, hideHeaders: true }
	},
	{ path: '/*', redirect: '/' }
]);

console.log(routes);

const router = new VueRouter({
	mode: 'history',
	routes
});

let debug = {
	enabled: true, // default value
	trace: true, // default value
	sendHitTask: false // default value
};

Vue.use(VueAnalytics, {
	id: 'UA-5755544-1',
	//debug,
	ignoreRoutes: [
		'droppoint',
		'login',
		'profile',
		'welcome',
		'case',
		'part-login',
		'part-case',
		'part-profile',
		'part-password',
		'part-validate',
		'part-welcome',
		'validate'
	],
	router
});

/** VUEX STORE */
const store = new Vuex.Store({
	state: {
		part: {},
		parttype: '',
		order: null,
		showOrders: false,
		validateUrl: '',
	},
	mutations: {
		part(state, part) {
			state.part = part;
		},
		parttype(state, type) {
			state.parttype = type;
		},
		order(state, order) {
			state.order = Object.assign({}, order);
		},
		showorders(state, show) {
			state.showOrders = show ? true : false;
		},
		validateurl(state, url) {
			state.validateUrl = url;
		}
	},
	actions: {
		getpart({ commit, state }, params) {
			return new Promise((resolve, reject) => {
				if (state.part.unique_hash == params.hash && !params.force) {
					if (params.loadCases && state.part.orders) {
						return resolve(state.part);
					} else if (!params.loadCases) {
						return resolve(state.part);
					}
				}

				let promise = null;
				switch (state.parttype) {
					case 'customer':
						promise = DataStore.getCustomer(params.hash, {
							loadcases: params.loadCases ? true : false
						});
						break;
					case 'part':
						promise = DataStore.getPart(params.hash, {
							loadcases: params.loadCases ? true : false,
							showmessagecount: true
						});
						break;
					default:
						reject(null);
						break;
				}

				promise.then((res) => {
					commit('part', res.data);
					resolve(state.part);
				});
			});
		}
	}
});

window.messageBus = new Vue({
	data() {
		return {
			intercomBooted: false
		};
	},
	watch: {
		boligData() {
			this.$emit('boligdata_updated', this.boligData);
		}
	},
	methods: {
		connectIntercom() {
			this.intercomBooted = true; //Disable intercom until correct implemented
			if (this.intercomBooted) return;

			if (!window.Intercom) return;

			let options = {
				app_id: 'qh4yaeai'
			};

			window.Intercom('boot', options);
			this.intercomBooted = true;
		}
	}
});

window.app = new Vue({
	router,
	store,
	watch: {
		'$route.path'() {
			this.setPartType();
		}
	},
	created() {
		this.setPartType();
	},
	mounted() {
		messageBus.$on('customer_invalid', () => {
			let hash = this.$route.params.hash;
			if (hash) {
				document.location = '/profil/' + hash + '/login';
			} else {
				document.location = '/';
			}
		});

		messageBus.$on('part_invalid', () => {
			let hash = this.$route.params.hash;
			if (hash) {
				document.location = '/part/login';
			} else {
				document.location = '/';
			}
		});
	},
	methods: {
		setPartType() {
			let path = this.$route.path;
			if (path.match(/^\/part/gi)) {
				this.$store.commit('parttype', 'part');
			} else if (path.match(/^\/profil/gi)) {
				this.$store.commit('parttype', 'customer');
			} else {
				this.$store.commit('part', {});
				this.$store.commit('parttype', '');
			}
		},
		back() {
			if (this.$route.params.hash) {
				this.$router.push({
					name: 'profile'
				});
			} else {
				document.location = '/';
			}
		}
	},
	computed: {
		showOrders() {
			return this.$store.state.showOrders ? true : false;
		},
		showMainHeader() {
			return !this.$route.meta.hideMainHeader;
		},
		showDDA() {
			return this.$route.meta.showDDA ? true : false;
		},
		hideHeaders() {
			return this.$route.meta.hideHeaders ? true : false;
		},
		showCaseView() {
			return this.$route.meta.caseView ? true : false;
		},
		showStTheme() {
			return this.$store.state.part.customer_department == 1 ? true : false;
		},
		showNem24Theme() {
			return this.$route.name == 'lead-view' ? true : false;
		},
		partType() {
			return this.$store.state.parttype ? this.$store.state.parttype : 'customer';
		},
		showSystemNotification() {
			return this.$route.meta.systemNotification;
		}
	},
	components: {
		'main-header': MainHeader,
		'nem-header': NemHeader,
		'dda-header': DDAHeader,
		'dda-footer': DDAFooter
	}
}).$mount('#app');
