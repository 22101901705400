var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "log-message",
    { attrs: { statuslog: _vm.statuslog } },
    [
      _vm.logType == "WELCOME_MESSAGE"
        ? [
            _c("template", { slot: "text" }, [
              _c("p", [_c("strong", [_vm._v("Hej " + _vm._s(_vm.lead.name))])]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Tak for din henvendelse til Nemadvokat. Vi har sendt en kvittering\n        til din mail med et link hertil. Vi finder nu en medarbejder til din\n        sag.\n      "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Mens du venter kan du bekræfte dine kundeoplysninger. Det hjælper os med at behandle din sag hurtigere.\n      "
                )
              ])
            ]),
            _vm._v(" "),
            _c("template", { slot: "actions" }, [
              _c(
                "a",
                {
                  staticClass: "btn btn--chat-link",
                  class: { "btn--clicked": _vm.customerInfoFilled },
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("setcustomerinfo")
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        !_vm.customerInfoFilled
                          ? "Bekræft kundeinformation"
                          : "Du har sendt kundeindformation"
                      ) +
                      "\n      "
                  )
                ]
              )
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.logType == "CUSTOMER_INFO_RECEIVED"
        ? [
            _c("template", { slot: "text" }, [
              _c("p", [
                _c("strong", [_vm._v("Kundeinformation er modtaget - tak!")])
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Dine oplysninger er modtaget og arkiveret.\n      "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Nu vil vi også gerne vide lidt mere om din sag. Bare rolig, vi forsætter processen uanset hvad, men jo flere oplysninger vi får, jo hurtigere kan vi hjælpe dig videre.\n      "
                )
              ])
            ]),
            _vm._v(" "),
            _c("template", { slot: "actions" }, [
              _vm.containsCaseData
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn--chat-link",
                      class: {
                        "btn--clicked":
                          _vm.choosedAction("CUSTOMER_CASEINFO_RECEIVED") ||
                          _vm.caseDataHasBeenFilled
                      },
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("setcasedata")
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            !_vm.caseDataHasBeenFilled
                              ? "Udfyld sagsdata"
                              : "Du har sendt sagsinformation"
                          ) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.containsCaseData
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn--chat-link",
                      class: {
                        "btn--clicked":
                          _vm.choosedAction("CUSTOMER_CASEINFO_RECEIVED") ||
                          _vm.caseDataHasBeenFilled
                      },
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("setcasedata")
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            !_vm.caseDataHasBeenFilled
                              ? "Udfyld sagsdata"
                              : "Du har sendt sagsinformation"
                          ) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e()
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.logType == "CUSTOMER_CASEINFO_RECEIVED"
        ? [
            _c("template", { slot: "text" }, [
              _c("p", [
                _c("strong", [_vm._v("Sagsdata er modtaget - perfekt!")])
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        De oplysninger, du har indtastet, er modtaget og arkiveret.\n      "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Hvis du ikke allerede har hørt fra os, vil du gøre det inden længe. Nu vi har lidt mere at abrejde med.\n      "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Ønsker du, at vi ringer til dig på et bestemt tidspunkt?\n      "
                )
              ])
            ]),
            _vm._v(" "),
            _c("template", { slot: "actions" }, [
              _c(
                "a",
                {
                  staticClass: "btn btn--chat-link",
                  class: {
                    "btn--clicked": _vm.choosedAction("CUSTOMER_WANTS_CALL")
                  },
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("setcontacttime")
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        !_vm.choosedAction("CUSTOMER_WANTS_CALL")
                          ? "Sæt tidspunkt"
                          : "Du har booket et telefonopkald"
                      ) +
                      "\n      "
                  )
                ]
              )
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.logType == "CUSTOMER_COMMENT_RECEIVED"
        ? [
            _c("template", { slot: "text" }, [
              _c("p", [_c("strong", [_vm._v("Din kommentar er modtaget")])]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Vi har sendt en notifikation til den ansvarlige medarbejder. Du kan forvente svar fra os indenfor to timer i vores åbningstid.\n      "
                )
              ])
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.logType == "CUSTOMER_COMMENT_ERROR"
        ? [
            _c("p", { attrs: { slot: "text" }, slot: "text" }, [
              _vm._v(
                "Der skete en fejl da kommentaren skulle sendes - prøv igen!"
              )
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.logType == "CUSTOMER_WANTS_CALL"
        ? [
            _c("template", { slot: "text" }, [
              _c("p", [
                _c("strong", [_vm._v("Det er en aftale - vi giver et kald.")])
              ]),
              _vm._v(" "),
              _vm.metadata.startTime && _vm.metadata.endTime
                ? _c("p", [
                    _vm._v(
                      "\n        Vi ringer til dig den\n        " +
                        _vm._s(
                          _vm
                            .getMoment(_vm.metadata.startTime)
                            .format("DD-MM-YYYY")
                        ) +
                        " mellem\n        " +
                        _vm._s(
                          _vm.getMoment(_vm.metadata.startTime).format("HH")
                        ) +
                        " -\n        " +
                        _vm._s(
                          _vm.getMoment(_vm.metadata.endTime).format("HH")
                        ) +
                        "\n      "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Ønsker du at ændre tidspunkt, kan du gøre det her.\n      "
                )
              ])
            ]),
            _vm._v(" "),
            _c("template", { slot: "actions" }, [
              _c(
                "a",
                {
                  staticClass: "btn btn--chat-link",
                  class: {
                    "btn--clicked": _vm.choosedAction("CUSTOMER_WANTS_CALL")
                  },
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("setcontacttime")
                    }
                  }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        !_vm.choosedAction("CUSTOMER_WANTS_CALL")
                          ? "Ændre tidspunkt"
                          : "Du har booket et telefonopkald"
                      ) +
                      "\n      "
                  )
                ]
              )
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.logType == "NO_CALL_RESPONSE_CUSTOMER"
        ? [
            _c("template", { slot: "text" }, [
              _c("p", [_c("strong", [_vm._v("Vi kunne ikke få fat i dig")])]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Jeg har forsøgt at ringe til dig uden held. Jeg prøver igen senere. Du er også velkommen til at vælge et tidspunkt her, hvor vi kan ringe til dig.\n      "
                )
              ])
            ]),
            _vm._v(" "),
            _c("template", { slot: "actions" }, [
              _c(
                "a",
                {
                  staticClass: "btn btn--chat-link",
                  class: {
                    "btn--clicked": _vm.choosedAction("CUSTOMER_WANTS_CALL")
                  },
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("setcontacttime")
                    }
                  }
                },
                [
                  _vm._v(
                    "\n       " +
                      _vm._s(
                        !_vm.choosedAction("CUSTOMER_WANTS_CALL")
                          ? "Sæt ønsket tidspunkt for opkald"
                          : "Du har booket et telefonopkald"
                      ) +
                      "\n      "
                  )
                ]
              )
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.logType == "CUSTOMER_ON_HOLD"
        ? [
            _c("template", { slot: "text" }, [
              _c("p", [_c("strong", [_vm._v("Henvendelse sat på hold")])]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Din henvendelse er sat på pause indtil  " +
                    _vm._s(
                      _vm.metadata.delayed_time
                        ? _vm
                            .getMoment(_vm.metadata.delayed_time)
                            .format("DD-MM-YYYY")
                        : ""
                    ) +
                    ".\n        Dette skyldes flere forsøg på kontakt elller en aftale med dig.\n      "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Ønsker du, at vi forsætter med det samme, kan du sende os en kommentar.\n      "
                )
              ])
            ]),
            _vm._v(" "),
            _c("template", { slot: "actions" }, [
              _c(
                "a",
                {
                  staticClass: "btn btn--chat-link",
                  class: {
                    "btn--clicked": _vm.choosedAction(
                      "CUSTOMER_COMMENT_RECEIVED"
                    )
                  },
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("comment")
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        !_vm.choosedAction("CUSTOMER_COMMENT_RECEIVED")
                          ? "Send en kommentar"
                          : "Du har sendt en kommentar"
                      ) +
                      "\n      "
                  )
                ]
              )
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.logType == "AWAITING_CUSTOMER_INFO"
        ? [
            _vm.metadata.reason
              ? _c("nl2br", {
                  attrs: { slot: "text", tag: "p", text: _vm.metadata.reason },
                  slot: "text"
                })
              : _vm._e(),
            _vm._v(" "),
            _c("template", { slot: "actions" }, [
              _c(
                "a",
                {
                  staticClass: "btn btn--chat-link",
                  class: {
                    "btn--clicked": _vm.choosedAction(
                      "CUSTOMER_COMMENT_RECEIVED"
                    )
                  },
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("comment")
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        !_vm.choosedAction("CUSTOMER_COMMENT_RECEIVED")
                          ? "Send en kommentar"
                          : "Du har sendt en kommentar"
                      ) +
                      "\n      "
                  )
                ]
              )
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.logType == "LEAD_ARCHIVED"
        ? [
            _c("template", { slot: "text" }, [
              _c("p", [
                _c("strong", [_vm._v("Din henvendelse er arkiveret.")])
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Vi foretager os ikke yderligere vedrørende denne henvendelse. Hvis du har spørgsmål eller kommentarer, kan du sende os en besked via knappen nedenfor."
                )
              ])
            ]),
            _vm._v(" "),
            _c("template", { slot: "actions" }, [
              _c(
                "a",
                {
                  staticClass: "btn btn--chat-link",
                  class: {
                    "btn--clicked": _vm.choosedAction(
                      "CUSTOMER_COMMENT_RECEIVED"
                    )
                  },
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("comment")
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        !_vm.choosedAction("CUSTOMER_COMMENT_RECEIVED")
                          ? "Send en kommentar"
                          : "Du har sendt en kommentar"
                      ) +
                      "\n      "
                  )
                ]
              )
            ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-c0bbfdde", { render: render, staticRenderFns: staticRenderFns })
  }
}