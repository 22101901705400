export default {
	1: 'WELCOME_MESSAGE',
	//2: 'WE_CALl_MESSAGE',
	//4: 'WE_WRITE_MESSAGE',
	5: 'CUSTOMER_INFO_RECEIVED',
	6: 'CUSTOMER_COMMENT_RECEIVED',
	7: 'CUSTOMER_WANTS_CALL',
	8: 'NO_CALL_RESPONSE_CUSTOMER',
	9: 'AWAITING_CUSTOMER_INFO',
	10: 'LEAD_ARCHIVED',
	11: 'CUSTOMER_CASEINFO_RECEIVED',
	12: 'CUSTOMER_ON_HOLD',
	// 13: 'WE_CALL_MESSAGE_RELATED_PRODUCTS',
	// 14: 'WE_WRITE_MESSAGE_RELATED_PRODUCTS',
	// 15: 'RELATED_PRODUCTS_ACCEPTED',
	// 16: 'RELATED_PRODUCTS_REJECTED',

    //Errors
    501: 'WELCME_ACTION_NOT_SET_ERROR',
    502: 'CUSTOMER_COMMENT_ERROR',
};
