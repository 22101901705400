<template>
  <div class="case-menu" :class="{'text-mode': textMode, 'input-mode': inputMode}"> <!--TODO toggle "text-mode" class to case menu when the "case-menu__text-mode__btn" is clicked-->
    <lead-comment
          :lead="lead"
          @input="(updatedLead) => $emit('updateLead', updatedLead)"
          :textMode="textMode"
          @textmode="setTextMode"
          @focus="inputMode = true"
          @blur="inputMode = false"
          :set-case-data="caseDataMode"
          @commenterror="$emit('commenterror')"
    ></lead-comment>
    <a 
      v-if="lead.contact_state != 4 && typeof lead.customer_name !== 'undefined'"
      :class="{ 'case-menu__item--verified': lead.customer_verified }"
      class="case-menu__item case-menu__item--tasks"
      href="javascript:void(0)"
      @click="fillCustomerData"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="case-menu__item__icon"><path d="M22.28,14.47l-2.25-2.25c-.07-.07-.15-.12-.24-.16-.09-.04-.19-.06-.29-.06s-.2.02-.29.06c-.09.04-.17.09-.24.16l-6.97,6.97v3.31h3.31l6.97-6.97c.07-.07.12-.15.16-.24.04-.09.06-.19.06-.29s-.02-.2-.06-.29c-.04-.09-.09-.17-.16-.24ZM14.69,21h-1.19v-1.19l3.75-3.75,1.19,1.19-3.75,3.75ZM19.5,16.19l-1.19-1.19,1.19-1.19,1.19,1.19-1.19,1.19Z" /><path d="M12.75,11.25h-4.5c-.6,0-1.17.24-1.59.66-.42.42-.66.99-.66,1.59v.75h1.5v-.75c0-.2.08-.39.22-.53.14-.14.33-.22.53-.22h4.5c.2,0,.39.08.53.22s.22.33.22.53v.75h1.5v-.75c0-.6-.24-1.17-.66-1.59-.42-.42-.99-.66-1.59-.66Z" /><path d="M10.5,10.5c.59,0,1.17-.18,1.67-.51.49-.33.88-.8,1.1-1.35.23-.55.29-1.15.17-1.73-.12-.58-.4-1.12-.82-1.54-.42-.42-.95-.71-1.54-.82-.58-.12-1.19-.06-1.73.17-.55.23-1.02.61-1.35,1.1-.33.49-.51,1.07-.51,1.67,0,.8.32,1.56.88,2.12.56.56,1.33.88,2.12.88ZM10.5,6c.3,0,.59.09.83.25.25.16.44.4.55.67.11.27.14.58.09.87-.06.29-.2.56-.41.77-.21.21-.48.35-.77.41s-.59.03-.87-.09c-.27-.11-.51-.31-.67-.55-.16-.25-.25-.54-.25-.83,0-.4.16-.78.44-1.06.28-.28.66-.44,1.06-.44Z" /><path d="M9,22.5h-4.5c-.4,0-.78-.16-1.06-.44-.28-.28-.44-.66-.44-1.06V3c0-.4.16-.78.44-1.06.28-.28.66-.44,1.06-.44h12c.4,0,.78.16,1.06.44.28.28.44.66.44,1.06v7.5h-1.5V3H4.5v18h4.5v1.5Z" /></svg>
      <span class="case-menu__item__text">Kundedata</span>
    </a>
    <router-link
      v-if="lead.contact_state != 4 && containsCaseData"
      :class="{ 'case-menu__item--verified': verifiedCaseData }"
      :to="{ name: 'lead-view', params: { view: 'products' } }"
      class="case-menu__item"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="case-menu__item__icon"><path d="M15,4.5H6v1.5h9v-1.5Z" /><path d="M15,7.5H6v1.5h9v-1.5Z" /><path d="M10.5,10.5h-4.5v1.5h4.5v-1.5Z" /><path d="M9,18h-3v1.5h3v-1.5Z" /><path d="M22.44,14.71c-.04-.09-.09-.17-.16-.24l-2.25-2.25c-.07-.07-.15-.12-.24-.16s-.19-.06-.29-.06-.2.02-.29.06-.17.09-.24.16l-6.97,6.97v3.31h3.31l6.97-6.97c.07-.07.12-.15.16-.24.04-.09.06-.19.06-.29s-.02-.2-.06-.29ZM14.69,21h-1.19v-1.19l3.75-3.75,1.19,1.19-3.75,3.75ZM19.5,16.19l-1.19-1.19,1.19-1.19,1.19,1.19-1.19,1.19Z" /><path d="M9,22.5h-4.5c-.4,0-.78-.16-1.06-.44-.28-.28-.44-.66-.44-1.06V3c0-.4.16-.78.44-1.06.28-.28.66-.44,1.06-.44h12c.4,0,.78.16,1.06.44.28.28.44.66.44,1.06v7.5h-1.5V3H4.5v18h4.5v1.5Z" /></svg>
      <span class="case-menu__item__text">Sagsdata</span>
    </router-link>
    <router-link
      :to="{ name: 'lead-view', params: { view: 'kommentar' } }"
      class="case-menu__item"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="case-menu__item__icon"><path d="M13.31,22.5l-1.31-.75,3-5.25h4.5c.4,0,.78-.16,1.06-.44s.44-.66.44-1.06V6c0-.4-.16-.78-.44-1.06s-.66-.44-1.06-.44H4.5c-.4,0-.78.16-1.06.44-.28.28-.44.66-.44,1.06v9c0,.4.16.78.44,1.06.28.28.66.44,1.06.44h6.75v1.5h-6.75c-.8,0-1.56-.32-2.12-.88-.56-.56-.88-1.33-.88-2.12V6c0-.8.32-1.56.88-2.12.56-.56,1.33-.88,2.12-.88h15c.8,0,1.56.32,2.12.88.56.56.88,1.33.88,2.12v9c0,.8-.32,1.56-.88,2.12-.56.56-1.33.88-2.12.88h-3.63l-2.56,4.5Z" /><path d="M12.75,6.75h-1.5v3h-3v1.5h3v3h1.5v-3h3v-1.5h-3v-3Z" /></svg>
      <span class="case-menu__item__text">Send kommentar</span>
    </router-link>
  </div>
</template>

<script>
import { HasCaseDataToFill } from "./casedata-util";
import inputField from '../input-field/input-field.vue';
import LeadComment from "./lead-comment.vue";

export default {
  props: {
    lead: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      inputMode: false
    }
  },
  methods: {
    fillCustomerData() {
      if (this.lead.customer_verified) {
        this.$router.push({
          name: "lead-view",
          params: { view: "customer-recept" },
        });
        return;
      }

      this.$router.push({
        name: "lead-view",
        params: { view: "kundevalidering" },
      });
    },
    setTextMode(textMode) {
      if(textMode) {
        this.openTextMode();
      } else {
        this.closeTextMode();
      }
    },
    closeTextMode() {
      this.$router.push({ name: "lead-view", params: { view: null } });
    },
    openTextMode() {
      this.$router.push({ name: "lead-view", params: { view: 'kommentar' } });
    }
  },
  computed: {
    products() {
      return this.lead && this.lead.products ? this.lead.products : [];
    },
    verifiedCaseData() {
      return this.products.filter(
        ({ company_data_filled }) => !company_data_filled
      ).length <= 0
        ? true
        : false;
    },
    containsCaseData() {
      return this.products.filter((leadProduct) =>
        HasCaseDataToFill(leadProduct)
      ).length > 0
        ? true
        : false;
    },
    textMode() {
      return this.$route.params.view == 'kommentar' ? true : false;
    },
    caseDataMode() {
      return this.$route.params.setcasedata ? true : false;
    }
  },
  components: {
      'inputfield': inputField,
      LeadComment
  },
};
</script>