<template>
  <log-message :statuslog="statuslog">
    <template v-if="logType == 'WELCOME_MESSAGE'">
      <template slot="text">
        <p>
          <strong>Hej {{ lead.name }}</strong>
        </p>
        <p>
          Tak for din henvendelse til Nemadvokat. Vi har sendt en kvittering
          til din mail med et link hertil. Vi finder nu en medarbejder til din
          sag.
        </p>
        <p>
          Mens du venter kan du bekræfte dine kundeoplysninger. Det hjælper os med at behandle din sag hurtigere.
        </p>
      </template>
      <template slot="actions">
        <a class="btn btn--chat-link" :class="{'btn--clicked': customerInfoFilled }" href="javascript:void(0)"
          @click="$emit('setcustomerinfo')">
          {{ (!customerInfoFilled) ? 'Bekræft kundeinformation' : 'Du har sendt kundeindformation' }}
        </a>
      </template>
    </template>

    <template v-if="logType == 'CUSTOMER_INFO_RECEIVED'">
      <template slot="text">
        <p><strong>Kundeinformation er modtaget - tak!</strong></p>
        <p>
          Dine oplysninger er modtaget og arkiveret.
        </p>
        <p>
          Nu vil vi også gerne vide lidt mere om din sag. Bare rolig, vi forsætter processen uanset hvad, men jo flere oplysninger vi får, jo hurtigere kan vi hjælpe dig videre.
        </p>
      </template>
      <template slot="actions">
        <a v-if="containsCaseData" class="btn btn--chat-link"  :class="{'btn--clicked': choosedAction('CUSTOMER_CASEINFO_RECEIVED') || caseDataHasBeenFilled }" href="javascript:void(0)" @click="$emit('setcasedata')">
          {{ (!caseDataHasBeenFilled) ? 'Udfyld sagsdata' : 'Du har sendt sagsinformation' }}
        </a>
        <a v-if="!containsCaseData" class="btn btn--chat-link"  :class="{'btn--clicked': choosedAction('CUSTOMER_CASEINFO_RECEIVED') || caseDataHasBeenFilled }" href="javascript:void(0)" @click="$emit('setcasedata')">
          {{ (!caseDataHasBeenFilled) ? 'Udfyld sagsdata' : 'Du har sendt sagsinformation' }}
        </a>
      </template>
    </template>

    <template v-if="logType == 'CUSTOMER_CASEINFO_RECEIVED'">
      <template slot="text">
        <p><strong>Sagsdata er modtaget - perfekt!</strong></p>
        <p>
          De oplysninger, du har indtastet, er modtaget og arkiveret.
        </p>
        <p>
          Hvis du ikke allerede har hørt fra os, vil du gøre det inden længe. Nu vi har lidt mere at abrejde med.
        </p>
        <p>
          Ønsker du, at vi ringer til dig på et bestemt tidspunkt?
        </p>
      </template>

      <template slot="actions">
        <a class="btn btn--chat-link" :class="{'btn--clicked': choosedAction('CUSTOMER_WANTS_CALL') }" href="javascript:void(0)" @click="$emit('setcontacttime')">
          {{ (!choosedAction('CUSTOMER_WANTS_CALL')) ? 'Sæt tidspunkt' : 'Du har booket et telefonopkald' }}
        </a>
      </template>
    </template>

    <template v-if="logType == 'CUSTOMER_COMMENT_RECEIVED'">
      <template slot="text">
        <p><strong>Din kommentar er modtaget</strong></p>
        <p>
          Vi har sendt en notifikation til den ansvarlige medarbejder. Du kan forvente svar fra os indenfor to timer i vores åbningstid.
        </p>
      </template>
    </template>


    <template v-if="logType == 'CUSTOMER_COMMENT_ERROR'">
      <p slot="text">Der skete en fejl da kommentaren skulle sendes - prøv igen!</p>
    </template>

    <template v-if="logType == 'CUSTOMER_WANTS_CALL'">
      <template slot="text">
        <p><strong>Det er en aftale - vi giver et kald.</strong></p>
        <p v-if="metadata.startTime && metadata.endTime">
          Vi ringer til dig den
          {{ getMoment(metadata.startTime).format("DD-MM-YYYY") }} mellem
          {{ getMoment(metadata.startTime).format("HH") }} -
          {{ getMoment(metadata.endTime).format("HH") }}
        </p>
        <p>
          Ønsker du at ændre tidspunkt, kan du gøre det her.
        </p>
      </template>
      <template slot="actions">
        <a class="btn btn--chat-link" :class="{'btn--clicked': choosedAction('CUSTOMER_WANTS_CALL') }" href="javascript:void(0)" @click="$emit('setcontacttime')">
        {{ (!choosedAction('CUSTOMER_WANTS_CALL')) ? 'Ændre tidspunkt' : 'Du har booket et telefonopkald' }}
        </a>
      </template>
    </template>

    <template v-if="logType == 'NO_CALL_RESPONSE_CUSTOMER'">
      <template slot="text">
        <p><strong>Vi kunne ikke få fat i dig</strong></p>
        <p>
          Jeg har forsøgt at ringe til dig uden held. Jeg prøver igen senere. Du er også velkommen til at vælge et tidspunkt her, hvor vi kan ringe til dig.
        </p>
      </template>
      <template slot="actions">
        <a class="btn btn--chat-link" :class="{'btn--clicked': choosedAction('CUSTOMER_WANTS_CALL') }" href="javascript:void(0)" @click="$emit('setcontacttime')">
         {{ (!choosedAction('CUSTOMER_WANTS_CALL')) ? 'Sæt ønsket tidspunkt for opkald' : 'Du har booket et telefonopkald' }}
        </a>
      </template>
    </template>

    <template v-if="logType == 'CUSTOMER_ON_HOLD'">
      <template slot="text">
        <p><strong>Henvendelse sat på hold</strong></p>
        <p>
          Din henvendelse er sat på pause indtil  {{ (metadata.delayed_time) ?
            getMoment(metadata.delayed_time).format("DD-MM-YYYY") : '' }}.
          Dette skyldes flere forsøg på kontakt elller en aftale med dig.
        </p>
        <p>
          Ønsker du, at vi forsætter med det samme, kan du sende os en kommentar.
        </p>
      </template>
      <template slot="actions">
        <a href="javascript:void(0)" class="btn btn--chat-link" :class="{'btn--clicked': choosedAction('CUSTOMER_COMMENT_RECEIVED') }" @click="$emit('comment')">
          {{ (!choosedAction('CUSTOMER_COMMENT_RECEIVED')) ? 'Send en kommentar' : 'Du har sendt en kommentar' }}
        </a>
      </template>
    </template>

    <template v-if="logType == 'AWAITING_CUSTOMER_INFO'">
      <nl2br slot="text" tag="p" v-if="metadata.reason" :text="metadata.reason" />
      <template slot="actions">
        <a href="javascript:void(0)" class="btn btn--chat-link" :class="{'btn--clicked': choosedAction('CUSTOMER_COMMENT_RECEIVED') }" @click="$emit('comment')">
          {{ (!choosedAction('CUSTOMER_COMMENT_RECEIVED')) ? 'Send en kommentar' : 'Du har sendt en kommentar' }}
        </a>
      </template>
    </template>


    <template v-if="logType == 'LEAD_ARCHIVED'">
      <template slot="text">
        <p>
          <strong>Din henvendelse er arkiveret.</strong>
        </p>
        <p>Vi foretager os ikke yderligere vedrørende denne henvendelse. Hvis du har spørgsmål eller kommentarer, kan du sende os en besked via knappen nedenfor.</p>
      </template>

      <template slot="actions">
        <a href="javascript:void(0)" class="btn btn--chat-link" :class="{'btn--clicked': choosedAction('CUSTOMER_COMMENT_RECEIVED') }" @click="$emit('comment')">
          {{ (!choosedAction('CUSTOMER_COMMENT_RECEIVED')) ? 'Send en kommentar' : 'Du har sendt en kommentar' }}
        </a>
      </template>
    </template>
  </log-message>
</template>

<script>
import Moment from "moment";
import nl2br from "vue-nl2br";
import LogMessage from './log-message.vue';

export default {
  props: {
    statuslog: {
      type: Object,
      default() {
        return {};
      },
    },
    lead: {
      type: Object,
      default() {
        return {};
      },
    },
    containsCaseData: {
      type: Boolean,
      default: false
    },
    caseDataHasBeenFilled: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    getMoment(time) {
      return Moment(time);
    },
    choosedAction(action) {
      if(!this.statuslog.nextLogStatus) {
        return false;
      }

      return this.statuslog.nextLogStatus == action ? true : false;
    },
  },
  computed: {
    logType() {
      return this.statuslog.logType;
    },
    metadata() {
      return this.statuslog && this.statuslog.metadata
        ? this.statuslog.metadata
        : {};
    },
    customerInfoFilled() {
      return typeof this.lead.customer_name !== 'undefined' && this.lead.customer_name ? true : false;
    }
  },
  components: {
    nl2br,
    LogMessage
  },
};
</script>