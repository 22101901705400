<template>

  <div class="case-menu__input">
    <input-field ref="commentField" :placeholder="placeholderTxt" inputType="textarea" v-model="comment" @focus="onInputFocus" @blur="onBlur"></input-field>
    <button class="case-menu__input__btn" :class="{ loading }" @click="sendComment"
      :disabled="comment.length <= 0">
      <span>Send</span>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.58 28.58">
        <path
          d="M25.99,10.65L6.31.74C4.75-.05,2.97.21,1.69,1.42.31,2.72-.1,4.7.66,6.48c1.02,2.4,2.21,5.24,2.32,5.51.32.77,1.04,1.31,1.87,1.31h9.43c1.32,0,1.32,2,0,2H4.85c-.77,0-1.52.46-1.89,1.33-.63,1.51-1.46,3.49-2.31,5.48-.76,1.77-.35,3.76,1.03,5.06.79.75,1.77,1.13,2.78,1.13.62,0,1.25-.15,1.85-.45l19.8-9.98c1.57-.79,2.45-2.5,2.14-4.3-.22-1.27-1.11-2.33-2.27-2.91h.01Z" />
      </svg>
    </button>
    <button class="case-menu__text-mode__btn" @click="toogleTextMode"></button>
  </div>
</template>

<script>
import InputField from "./../input-field/input-field.vue";
import DataStore from "../../services/datastore";
import Modal from "./../modal/base-modal.vue";

export default {
  props: {
    lead: { type: Object, default: null },
    textMode: {type: Boolean, default: false},
    setCaseData: {type: Boolean, default: false }
  },
  data() {
    return {
      comment: "",
      loading: false,
      error: false,
    };
  },
  methods: {
    onInputFocus() {
      this.$emit('focus');
    },
    onBlur() {
      this.$emit('blur');
    },
    openTextMode() {
      this.$emit('textmode', true);
      this.$nextTick(() => {
        if(this.$refs.commentField) {
          this.$refs.commentField.selectInputField();
        }
      })
    },
    closeTextMode() {
      this.$emit('textmode', false);
    },
    toogleTextMode() {
      if(this.textMode) {
        this.closeTextMode();
      } else {
        this.openTextMode();
      }
    },
    sendComment() {
      if (!this.comment) return;

      this.error = false;
      this.loading = true;

      DataStore.addLeadComment(this.lead.hash, { comment: this.comment, setcasedata: this.setCaseData })
        .then(({ data }) => {
          this.loading = false;

          this.$emit(
            "input",
            Object.assign({}, this.lead)
          );

          this.comment = "";
          this.closeTextMode();
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
          this.$emit('commenterror');
          this.closeTextMode();
        });
    },
  },
  computed: {
    placeholderTxt() {
      return this.setCaseData ? 'Skriv lidt om din sag' : 'Send meddelse';
    }
  },
  components: {
    InputField,
    Modal,
  },
};
</script>