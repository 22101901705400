<template>
    <div class="log-message" v-if="logType" :class="[
        'log-message-type--' + logType,
        { 'log-message-error': statuslog.contact_log_status > 500 },
    ]">
        <div class="log-message__header">
            <span class="log-message__date">{{ date }}</span>
            <span class="log-message__name">{{ name }}</span>
        </div>
        <div class="log-message__body">
            <div class="log-message__text">
                <slot name="text"></slot>
            </div>
            <img :src="image" class="log-message__avatar" />
        </div>
        <div class="chat-actions" v-if="hasActionSlot">
            <slot name="actions"></slot>
        </div>
    </div>
</template>

<script>
import Moment from "moment";
export default {
    props: {
        statuslog: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    computed: {
        date() {
            return this.statuslog
                ? Moment(this.statuslog.created_at).format("lll")
                : "";
        },
        name() {
            return this.statuslog && this.statuslog.user
                ? this.statuslog.user.fullname
                : "Nemadvokat";
        },
        logType() {
            return this.statuslog.logType;
        },
        image() {
            if (this.statuslog && this.statuslog.user) {
                return this.statuslog.user.image
                    ? this.statuslog.user.image
                    : "/assets/img/client-icon.svg";
            }

            return "/assets/img/logos/nemadvokat-logo-min.svg";
        },
        hasActionSlot() {
            return this.$slots.actions ? true : false;
        }
    }
}
</script>