var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "case-menu__input" },
    [
      _c("input-field", {
        ref: "commentField",
        attrs: { placeholder: _vm.placeholderTxt, inputType: "textarea" },
        on: { focus: _vm.onInputFocus, blur: _vm.onBlur },
        model: {
          value: _vm.comment,
          callback: function($$v) {
            _vm.comment = $$v
          },
          expression: "comment"
        }
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "case-menu__input__btn",
          class: { loading: _vm.loading },
          attrs: { disabled: _vm.comment.length <= 0 },
          on: { click: _vm.sendComment }
        },
        [
          _c("span", [_vm._v("Send")]),
          _vm._v(" "),
          _c(
            "svg",
            {
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 28.58 28.58"
              }
            },
            [
              _c("path", {
                attrs: {
                  d:
                    "M25.99,10.65L6.31.74C4.75-.05,2.97.21,1.69,1.42.31,2.72-.1,4.7.66,6.48c1.02,2.4,2.21,5.24,2.32,5.51.32.77,1.04,1.31,1.87,1.31h9.43c1.32,0,1.32,2,0,2H4.85c-.77,0-1.52.46-1.89,1.33-.63,1.51-1.46,3.49-2.31,5.48-.76,1.77-.35,3.76,1.03,5.06.79.75,1.77,1.13,2.78,1.13.62,0,1.25-.15,1.85-.45l19.8-9.98c1.57-.79,2.45-2.5,2.14-4.3-.22-1.27-1.11-2.33-2.27-2.91h.01Z"
                }
              })
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c("button", {
        staticClass: "case-menu__text-mode__btn",
        on: { click: _vm.toogleTextMode }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-771c3461", { render: render, staticRenderFns: staticRenderFns })
  }
}