var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nem-header" }, [
      _c("div", { staticClass: "nem-wrapper" }, [
        _c(
          "a",
          {
            staticClass: "nem-header__logo",
            attrs: { href: "https://www.nemadvokat.dk" }
          },
          [
            _c("img", {
              staticClass: "nem-header__logo__new",
              attrs: {
                src: "/assets/img/logos/nemadvokat-logo.svg",
                alt: "Nemadvokat"
              }
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "nem-header__logo__old",
              attrs: {
                src: "/assets/img/nemadvokat-logo.svg",
                alt: "NEMADVOKAT"
              }
            })
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "nem-header__phone",
            attrs: { href: "tel:70 20 41 61", target: "_blank" }
          },
          [_vm._v("Tlf."), _c("span", [_vm._v(" 70 20 41 61")])]
        )
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-cca4f72e", { render: render, staticRenderFns: staticRenderFns })
  }
}