<template>
  <div
    class="lead-info lead-info--log-messages"
    v-if="lead"
    :class="{ loading: loading }"
    ref="logmessages"
  >
    <p v-if="error" class="error">
      Der skete en fejl og data kunne ikke hentes...
      <button class="btn btn--default" @click="reload">Prøv igen</button>
    </p>

    <div class="messages">
      <status-log-message
        :statuslog="logEntry"
        :lead="lead"
        :key="logEntry.id"
        :containsCaseData="containsCaseData"
        :caseDataHasBeenFilled="caseDataHasBeenFilled"
        v-for="logEntry in logList"
        @setcontacttime="setContactTime"
        @comment="setComment"
        @setcustomerinfo="setCustomerInfo"
        @setcasedata="setCaseData"
      ></status-log-message>
    </div>
  </div>
</template>

<script>
import DataStore from "../../services/datastore";
import StatusLogMessage from "./lead-status-log-message.vue";
import LOG_STATUS_TYPES from "./logstatus-types";
import { HasCaseDataToFill } from "./casedata-util";

let scrollTimer = null;

export default {
  props: {
    lead: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      statusLog: [],
      loading: false,
      error: false,
    };
  },
  watch: {
    lead() {
      this.getContactStatusLog();
    },
    logList(newLogList, oldLogList) {
      this.$nextTick(() => {
        if (
          oldLogList &&
          newLogList &&
          newLogList.length != oldLogList.length
        ) {
          this.$nextTick(() => {
            this.scrollToBottom();
          });
        }
      });
    },
  },
  mounted() {
    this.reload();
  },
  methods: {
    scrollToBottom() {
      if (this.$refs.logmessages) {
        if (scrollTimer) {
          clearTimeout(scrollTimer);
        }

        scrollTimer = setTimeout(() => {
          let offset = window.outerHeight - this.$refs.logmessages.clientHeight;
          this.$refs.logmessages.scrollTo(
            0,
            this.$refs.logmessages.scrollHeight + offset
          );
        }, 10);
      }
    },
    reload() {
      this.statusLog = [];
      return this.getContactStatusLog();
    },
    getContactStatusLog(force = false) {
      if (this.loading && !force) return;

      this.loading = true;
      this.error = false;
      return DataStore.getLeadContactLog(this.lead.hash)
        .then(({ data }) => {
          this.statusLog = data;
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
          this.error = true;
        });
    },
    setContactTime() {
      this.$router.push({
        name: "lead-view",
        params: {
          view: "book-telefon-mode",
        },
      });
    },
    setComment() {
      this.$router.push({
        name: "lead-view",
        params: {
          view: "kommentar",
        },
      });
    },
    setCustomerInfo() {
      if (this.lead.customer_verified) {
        this.$router.push({
          name: "lead-view",
          params: { view: "customer-recept" },
        });
        return;
      }

      this.$router.push({
        name: "lead-view",
        params: { view: "kundevalidering" },
      });
    },
    setCaseData() {
      if (!this.containsCaseData) {
        this.$router.push({
        name: "lead-view",
        params: {
          view: "kommentar",
          setcasedata: true
        },
      });
      } else {
        this.$router.push({
          name: "lead-view",
          params: { view: "products" },
        });
      }
    }
  },
  computed: {
    leadDisabled() {
      return this.lead.contact_state == 4 ? true : false;
    },
    logList() {
      const filteredStatusLog = this.statusLog
        .filter((message) => {
          return LOG_STATUS_TYPES[message.contact_log_status] !== undefined;
        });

      return filteredStatusLog
        .map((message, key) => {
          let logType = LOG_STATUS_TYPES[message.contact_log_status];

          const nextLogStatus = (filteredStatusLog[key + 1]) ? LOG_STATUS_TYPES[filteredStatusLog[key + 1].contact_log_status] : null;
          return Object.assign({}, message, {
            logType,
            nextLogStatus
          });
        });
    },
    caseDataHasBeenFilled() {
      return this.logList.filter(({ contact_log_status }) => {
        return contact_log_status == 11
      }).length ? true : false;
    },
    products() {
      return this.lead && this.lead.products ? this.lead.products : [];
    },
    mainProductName() {
      if (!this.products.length) {
        return '';
      }

      let mainProduct = this.products[0];
      return (mainProduct.product && mainProduct.product.title) ? mainProduct.product.title.toLowerCase() : '';
    },
    containsCaseData() {
      return this.products.filter((leadProduct) =>
        HasCaseDataToFill(leadProduct)
      ).length > 0
        ? true
        : false;
    }
  },
  components: {
    StatusLogMessage,
  },
};
</script>