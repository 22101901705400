import Axios from 'axios';

class DataStore {

  constructor(API_URL) {
    this.API_URL = API_URL;

    Axios.interceptors.response.use((response) => {
      if (response.data.status === 'error') {
        switch (response.data.code) {
          case 'part_validate':
            messageBus.$emit('part_invalid');
            break;
          case 'customer_validate':
            messageBus.$emit('customer_invalid');
            break;
          default:
            return response;
        }
      }

      return response;
    }, (error) => {
      return Promise.reject(error);
    });
  }

  getCityFromZipcode(zipcode) {
    return Axios.get(this.API_URL + "/zipcode/" + zipcode);
  }

  createLead(params) {
    return Axios.post(this.API_URL + '/lead', params);
  }

  getLead(hash) {
    return Axios.get(this.API_URL + '/lead/' + hash);
  }

  getLeadContactLog(hash) {
    return Axios.get(this.API_URL + '/lead/' + hash + '/contactlog');
  }

  updateLead(hash, params) {
    return Axios.post(this.API_URL + '/lead/' + hash, params);
  }

  updateCompanyLead(hash, params) {
    return Axios.post(this.API_URL + '/lead/' + hash + '/company', params);
  }

  addLeadComment(hash, params) {
    return Axios.post(this.API_URL + '/lead/' + hash + '/comment', params);
  }

  verifyLeadCustomerData(hash, params) {
    return Axios.post(this.API_URL + '/lead/' + hash + '/customerverify', params);
  }

  // mailInsightRequest(hash) {
  //   return Axios.post(this.API_URL + '/customer/' + hash + '/insightrequest');
  // }

  getCprValidate(hash) {
    return Axios.get(this.API_URL + '/customer/' + hash + '/cprvalidate');
  }

  getCustomerKYCValidation(hash) {
    return Axios.get(this.API_URL + '/customer/' + hash + '/kycvalidation');
  }

  getCustomerRealOwners(hash) {
    return Axios.get(this.API_URL + '/customer/' + hash + '/realowners');
  }

  createCustomerKYCValidation(hash) {
    return Axios.post(this.API_URL + '/customer/' + hash + '/kycvalidation');
  }

  getCustomer(hash, params) {
    return Axios.get(this.API_URL + '/customer/' + hash, { params });
  }

  customerValidate(hash, state, params) {
    if (!params) {
      params = {};
    }

    params['state'] = state;
    return Axios.post(this.API_URL + '/customer/' + hash + '/validate', params);
  }

  customerReset(email) {
    return Axios.post(this.API_URL + '/customer/reset', { email });
  }

  // customerLogout() {
  //   return Axios.post(this.API_URL + '/customer/logout');
  // }

  partLogin(email, password) {
    return Axios.post(this.API_URL + '/part/login', {
      email,
      password
    });
  }

  // partLogout() {
  //   return Axios.post(this.API_URL + '/part/logout');
  // }

  logout() {
    return Axios.post(this.API_URL + '/logout');
  }

  partSetPassword(part_hash, password) {
    return Axios.post(this.API_URL + '/part/' + part_hash + '/password', {
      password
    });
  }

  partValidateEmail(part_hash) {
    return Axios.post(this.API_URL + '/part/' + part_hash + '/validate');
  }

  getPart(part_hash, params) {
    params = Object.assign({}, params, { part_hash });
    console.log('params', params);
    return Axios.get(this.API_URL + '/part/', { params });
  }

  getOrder(main_order_hash, part_hash) {
    return Axios.get(this.API_URL + '/orders/' + main_order_hash + '/', {
      params: {
        part_hash
      }
    });
  }

  getAftaleBrev(main_order_hash) {
    return Axios.get(this.API_URL + '/orders/' + main_order_hash + '/aftalebrev');
  }

  getAftaleBrevFile(main_order_hash, letter_id) {
    return Axios.get(this.API_URL + '/orders/' + main_order_hash + '/aftalebrev/file/' + letter_id, { responseType: 'arraybuffer' }).then((response) => {
      let blob = new Blob([response.data], { type: response.headers['content-type'] });
      return blob;
    });
  }

  approveAftaleBrev(main_order_hash, letter_id) {
    return Axios.post(this.API_URL + '/orders/' + main_order_hash + '/aftalebrev', { letter_id });
  }

  getMessages(main_order_hash, markAsRead, part_hash) {
    return Axios.post(this.API_URL + '/orders/' + main_order_hash + '/messages', {
      mark_read: (markAsRead) ? true : false
    }, {
      params: {
        part_hash
      }
    });
  }

  addMessage(main_order_hash, formData, part_hash) {
    return Axios.post(this.API_URL + '/orders/' + main_order_hash + '/messages/send', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      params: {
        part_hash
      }
    });
  }

  getSharedFiles(main_order_hash, part_hash) {
    return Axios.get(this.API_URL + '/orders/' + main_order_hash + '/sharedfiles', {
      params: {
        part_hash
      }
    });
  }

  getSharedFileByFileId(main_order_hash, file_id, part_hash) {
    return Axios.get(this.API_URL + '/orders/' + main_order_hash + '/sharedfiles/' + file_id, { responseType: 'arraybuffer', params: { part_hash } }).then((response) => {
      let blob = new Blob([response.data], { type: response.headers['content-type'] });
      return blob;
    });
  }

  getUploadUrl(main_order_hash, part_hash) {
    let url = this.API_URL + '/orders/' + main_order_hash + '/file';
    return (part_hash) ? url + '?part_hash=' + part_hash : url;
  }

  getPublicUploadUrl(main_order_hash) {
    return this.API_URL + '/droppoint/' + main_order_hash;
  }

  getPublicOrder(main_order_hash) {
    return Axios.get(this.API_URL + '/droppoint/' + main_order_hash);
  }

  productList() {
    return Axios.get(this.API_URL + '/products');
  }

  campaignProductList(params) {
    return Axios.get(this.API_URL + '/campaign/products', { params });
  }

  campaignProduct(sourceslug, campaignslug, type = 1) {
    return Axios.get(this.API_URL + '/product/' + sourceslug + '/' + campaignslug, {
      params: {
        type
      }
    });
  }

  campaignSource(sourceslug) {
    return Axios.get(this.API_URL + '/campaign/source/' + sourceslug);
  }

  campaignContent(campaign_id, params) {
    return Axios.get(this.API_URL + '/campaign/content/' + campaign_id, { params });
  }

  campaignContentIamge(campaign_id, content_area_name) {
    return this.API_URL + '/campaign/content/' + campaign_id + '?return_type=image&content_area_name[]=' + content_area_name;
  }

  getKYCValidateTypes() {
    return Axios.get(this.API_URL + '/kycvalidate/types');
  }

  getKYCValidation(hash, params) {
    return Axios.get(this.API_URL + '/kycvalidate/' + hash, { params });
  }

  // KYCValidateUpdate(hash, params) {
  //   return Axios.post(this.API_URL + '/kycvalidate/' + hash, params);
  // }

  KYCAddValidationMethod(hash, params) {
    return Axios.post(this.API_URL + '/kycvalidate/' + hash + '/add', params);
  }

  KYCRemoveValidationMethod(hash, params) {
    return Axios.post(this.API_URL + '/kycvalidate/' + hash + '/remove', params);
  }

  KYCSignValidationFileURL(hash, type) {
    return this.API_URL + '/kycvalidate/' + hash + '/signfile/' + type;
  }
}

module.exports = new DataStore("/api");
